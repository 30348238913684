import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";

import StandardLayout from "./StandardLayout";

import missionIcon from "../images/missionIcon.svg";
import visionIcon from "../images/visionIcon.svg";

import brainicon from "../images/brainicon.svg";
import elipses from "../images/elipses.svg";

const PhilosophyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin: auto;

  max-width: 700px;
  text-align: center;

  .background {
    position: absolute;
    z-index: -1;
    background-image: url(${elipses});
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
`;

export default function Philosophy() {
  return (
    <StandardLayout>
      <PhilosophyStyled>
        <div className="background"></div>
        <img src={brainicon} alt="" />
        <h2>Nuestra filosofía</h2>
        <p>
          Creemos firmemente, que para satifacer las necesidades de nuestros
          clientes es preciso brindar soluciones inteligentes al flujo de
          información de la manera mas eficaz y funcional, con un enfoque de
          mejora continua y la optimización en nuestros procesos.
        </p>
      </PhilosophyStyled>
    </StandardLayout>
  );
}
