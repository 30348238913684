import React, { useContext } from "react";
import styled from "styled-components";

import { context } from "../context/CustomProvider";

import PrimaryButton from "./PrimaryButton";
import Fade from "react-reveal/Fade";

import StandardLayout from "./StandardLayout";
import colors from "../styles/colors";

import background from "../images/bg1.svg";
import HomeCarousel from "./HomeCarousel";
import CarouselCustom from "./CarouselCustom";

const MainContainerStyled = styled.div`
  position: relative;
  width: 100%;
  max-width: 1500px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 700px;
  margin: auto;
  margin-top: 50px;

  @media (max-width: 1000px) {
    height: 900px;
  }

  h1 {
    color: white;
    font-size: 5rem;
  }

  .mobile-background {
    margin: 0 4rem;
    margin-top: 150px;
    max-width: 400px;
    justify-self: center;
    align-self: center;
    display: none;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;

    .mobile-background {
      display: inline;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 3rem;
  }

  .left-container {
    align-self: center;
    justify-self: right;
    max-width: 60rem;

    @media (max-width: 1000px) {
      margin-right: 0;
      justify-self: center;
      align-self: center;
      text-align: center;
      padding: 0 1rem;
      grid-row: 2;
    }

    .btn-container {
      display: flex;
      gap: 1rem;

      @media (max-width: 1000px) {
        justify-content: center;
      }
    }
  }

  .right-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1000px) {
      grid-row: 1;
      align-items: center;
    }
  }

  .title-highlight {
    color: ${colors.secondary};
    text-decoration: underline;
    font-weight: 400;
  }

  p {
    margin-top: 4rem;
    margin-bottom: 4rem;
    line-height: 2.5rem;
    font-weight: 600;
    color: ${colors.darkGray};

    span {
      font-weight: 600;
      color: white;
    }
  }

  .background {
    position: absolute;
    width: 100%;
    margin: auto;
    height: 100%;
    z-index: -1;
    text-align: center;
    overflow: hidden;

    background-image: url(${background});
    background-size: stretch;
    background-repeat: no-repeat;

    @media (max-width: 1000px) {
      margin-top: 300px;
    }
  }
`;

export default function LandingTop() {
  const { setContactModal, contactModal } = useContext(context);
  return (
    <>
      <MainContainerStyled>
        <Fade left>
          <div className="left-container">
            <h1>
              Soluciones <span class="title-highlight">innovadoras</span>
              <br />
              para tu empresa
            </h1>
            <p>
              Tenemos un enfoque de mejora continua y la
              <span> optimización</span> de nuestros procesos.
            </p>
            <Fade bottom delay={1000}>
              <div className="btn-container">
                <PrimaryButton
                  text={"Contáctanos"}
                  anchor="https://api.whatsapp.com/send?phone=+522215823597"
                ></PrimaryButton>
              </div>
            </Fade>
          </div>
        </Fade>
        <Fade right>
          <div className="right-container">
            <CarouselCustom></CarouselCustom>
          </div>
        </Fade>
        <div className="background">
          {/* <img src={background} alt="" /> */}
        </div>
      </MainContainerStyled>
    </>
  );
}
