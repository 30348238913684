import React from "react";
import styled from "styled-components";

import colors from "../styles/colors";

const ServiceCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 350px;

  .card-title {
    border-left: 4px solid ${colors.secondary};
    padding-left: 30px;
  }

  p {
    color: ${colors.darkGray};
    cursor: pointer;
    margin-left: 35px;

    &:hover {
      color: ${colors.primary};
    }
  }

  img {
    height: 50px;
    width: auto;
    margin-left: 35px;
  }
`;

export default function ServiceCard({ icon, title, items }) {
  return (
    <ServiceCardStyled>
      <img src={icon}></img>
      <h2 class="card-title">{title}</h2>
      <div>
        {items?.map((el) => (
          <p>{el}</p>
        ))}
      </div>
    </ServiceCardStyled>
  );
}
