import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";
import StandardLayout from "./StandardLayout";

import hp from "../images/providers/hp.png";

const ProvidersStyled = styled.div`
  text-align: center;

  .providers {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10rem;
    row-gap: 4rem;
    justify-content: center;
    align-items: center;

    img {
      max-width: 12rem;
    }
  }
`;

export default function FeaturedGeneric({ children, title }) {
  return (
    <ProvidersStyled>
      <h2 style={{ color: colors.darkGray }}>{title}</h2>
      <div className="providers">{children}</div>
    </ProvidersStyled>
  );
}
