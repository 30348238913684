import React from "react";
import styled from "styled-components";

const CardStyled = styled.div`
  width: 250px;
  background-color: white;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 10px 0px 50px rgba(0, 0, 0, 0.2);
  }

  .content {
    padding: 3rem;
    text-align: left;

    p {
      font-size: 1.4rem;
      margin-top: 3rem;
    }
  }
`;

export default function ProjectCard({ title, text, image }) {
  return (
    <CardStyled>
      <div>
        <img src={image} alt="" />
      </div>
      <div className="content">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </CardStyled>
  );
}
