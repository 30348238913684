import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";
import StandardLayout from "./StandardLayout";

import hp from "../images/providers/hp.png";
import toshiba from "../images/providers/toshiba.png";
import logitech from "../images/providers/logitech.png";
import samsung from "../images/providers/samsung.png";
import intel from "../images/providers/intel.png";
import lg from "../images/providers/lg.png";
import panduit from "../images/providers/panduit.png";
import mcafee from "../images/providers/mcafee.png";
import eset from "../images/providers/eset.png";
import symantec from "../images/providers/symantec.png";
import panda from "../images/providers/panda.png";
import systimax from "../images/providers/systimax.png";
import bosch from "../images/providers/bosch.png";
import epson from "../images/providers/epson.png";
import dell from "../images/providers/dell.png";
import kaspersky from "../images/providers/kaspersky.png";
import axis from "../images/providers/axis.png";
import hikvision from "../images/providers/hikvision.png";
import avaya from "../images/providers/avaya.png";
import nvidia from "../images/providers/nvidia.png";
import FeaturedGeneric from "./FeaturedGeneric";

export default function Providers() {
  return (
    <StandardLayout backgroundColor={"white"}>
      <FeaturedGeneric title="Nuestros proveedores">
        <img src={hp} alt="" />
        <img src={toshiba} alt="" />
        <img src={logitech} alt="" />
        <img src={samsung} alt="" />
        <img src={intel} alt="" />
        <img src={lg} alt="" />
        <img src={panduit} alt="" />
        <img src={mcafee} alt="" />
        <img src={eset} alt="" />
        <img src={symantec} alt="" />
        <img src={panda} alt="" />
        <img src={systimax} alt="" />
        <img src={bosch} alt="" />
        <img src={epson} alt="" />
        <img src={dell} alt="" />
        <img src={kaspersky} alt="" />
        <img src={axis} alt="" />
        <img src={hikvision} alt="" />
        <img src={avaya} alt="" />
        <img src={nvidia} alt="" />
      </FeaturedGeneric>
    </StandardLayout>
  );
}
