import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";
import MemberItem from "./MemberItem";

import member1 from "../images/team-members/member1.png";
import member2 from "../images/team-members/member2.png";
import member3 from "../images/team-members/member3.png";

import hline from "../images/hline.svg";

const TeamArrayStyled = styled.div`
  .division {
    margin-bottom: 3rem;
  }
`;

export default function TeamArray() {
  return (
    <TeamArrayStyled>
      <div className="division">
        <img src={hline} style={{ position: "absolute", zIndex: "-1" }} />
        <MemberItem
          name="Daniel Rojas"
          description={"Hola soy Gerente operativo de Proizd!"}
          image={member1}
        ></MemberItem>
      </div>
      <div className="division">
        <img src={hline} style={{ position: "absolute", zIndex: "-1" }} />
        <MemberItem
          right={true}
          name="Cinthia Ramirez"
          description={"Hola soy coordinadora de ingeniería en Proizd!"}
          image={member2}
        ></MemberItem>
      </div>
      <div className="division">
        <img src={hline} style={{ position: "absolute", zIndex: "-1" }} />
        <MemberItem
          name="Itzel Santibañez"
          description={"Hola soy coordinadora de ventas en Proizd!"}
          image={member3}
        ></MemberItem>
      </div>
    </TeamArrayStyled>
  );
}
