import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";

import StandardLayout from "./StandardLayout";

import missionIcon from "../images/missionIcon.svg";
import visionIcon from "../images/visionIcon.svg";

import vline from "../images/vline.svg";

const MissionStatementStyled = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  div {
    position: relative;
    padding: 0 8rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

    @media (max-width: 800px) {
      padding: 0 2rem;
    }

    .vdivider {
      position: absolute;
      right: 0;
      max-height: 250px;

      @media (max-width: 600px) {
        display: none;
      }
    }
  }

  h2 {
    color: white;
  }

  p {
    color: white;
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }
`;

export default function MisionStatement() {
  return (
    <StandardLayout backgroundColor={colors.darkBlue}>
      <MissionStatementStyled>
        <div>
          <img src={missionIcon} alt="" />
          <h2>Misión</h2>
          <p>
            Desarrollar óptimas soluciones y de alto valor desde la estrategia a
            la ejecución en el area de informatica, redes, telecomunicaciones y
            sistemas de seguridad, cubriendo siempre las necesidades e
            impulsando con innovación la mejora continua de su organización.
          </p>
          <img src={vline} className="vdivider" alt="" />
        </div>
        <div>
          <img src={visionIcon} alt="" />
          <h2>Visión</h2>
          <p>
            Ser la empresa líder en soluciones tecnológicas a nivel estatal,
            fomentando el establecimiento de una atmósfera mas segura y
            funcional, manteniendo la mas alta calidad y excelencia en la
            implementación de nuestros servicios.
          </p>
        </div>
      </MissionStatementStyled>
    </StandardLayout>
  );
}
