import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";

const TeamMemberStyled = styled.div`
  display: inline-block;
  margin-left: 15rem;

  @media (max-width: 650px) {
    margin: 0;
  }

  .dash-red {
    width: 40px;
    height: 1px;
    background-color: ${colors.secondary};
    margin-left: 40px;
    margin-bottom: 30px;
  }

  .container {
    position: relative;
    margin: 1rem;
  }

  .picture {
    position: absolute;
    left: 0;
    top: -10px;
    img {
      border-radius: 100px;

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
  }

  .card {
    margin-left: 20px;
    background-color: ${colors.darkRed};
    display: inline-box;
    width: 400px;
    padding-left: 9rem;
    height: 80px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    @media (max-width: 550px) {
      width: 300px;
    }
  }

  .card-text {
    padding: 1.4rem;
    height: 100%;
    color: white;
  }

  h3 {
    color: inherit;
  }

  p {
    color: inherit;
    font-size: 1.2rem;
  }
`;

const TeamMemberStyledRight = styled.div`
  display: flex;

  .dash-red {
    position: absolute;
    right: 180px;
    width: 40px;
    height: 1px;
    background-color: ${colors.secondary};
  }

  .container {
    position: relative;
    margin-left: auto;
    margin-right: 15rem;
    margin-top: 3rem;
  }

  .picture {
    position: absolute;
    right: 0;
    top: -10px;
    img {
      border-radius: 100px;

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
  }

  .card {
    text-align: right;
    margin-right: 20px;
    background-color: ${colors.darkRed};
    display: inline-box;
    width: 400px;
    padding-right: 9rem;
    height: 80px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    @media (max-width: 550px) {
      width: 300px;
    }
  }

  .card-text {
    padding: 1.4rem;
    height: 100%;
    color: white;
  }

  h3 {
    color: inherit;
  }

  p {
    color: inherit;
    font-size: 1.2rem;
  }
`;

export default function MemberItem({ right, image, name, description }) {
  const MemberStyled = right ? TeamMemberStyledRight : TeamMemberStyled;

  return (
    <MemberStyled>
      <div className="dash-red"></div>
      <div className="container">
        <div className="picture">
          <img src={image} width="100px" alt="" />
        </div>
        <div className="card">
          <div className="card-text">
            <h3>{name}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </MemberStyled>
  );
}
