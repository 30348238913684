import React from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import colors from "../styles/colors";
import StandardLayout from "./StandardLayout";

import fig2 from "../images/graphs/fig2.svg";

const ContentSectionStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }

  div {
    align-self: center;
    justify-self: center;
  }

  h3 {
    font-weight: 800;
    margin-bottom: 1rem;
    color: ${colors.darkGray};
  }

  h2 {
    font-weight: 800;
  }

  h1 {
    font-size: 4rem;
    margin-bottom: 4rem;
    color: white;
  }

  img {
    max-width: 350px;
    height: auto;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  }

  p {
    color: white;
    margin-bottom: 2rem;
  }

  .background-fig {
    position: absolute;
    left: 20px;
    top: -30px;
    width: 150px;
    box-shadow: none;

    @media (max-width: 650px) {
      display: none;
      visibility: hidden;
    }
  }

  .right-picture {
    @media (max-width: 650px) {
      display: none;
      visibility: hidden;
    }
  }
`;

export default function ContentSection({
  firstTitle,
  secondTitle,
  picture,
  children,
  imageStyles,
}) {
  return (
    <StandardLayout backgroundColor={colors.darkRed}>
      <ContentSectionStyled>
        <img src={fig2} class="background-fig"></img>

        <Fade left>
          <div className="right-picture" style={imageStyles}>
            <img src={picture}></img>
          </div>
        </Fade>
        <Fade right>
          <div>
            <h3>{firstTitle}</h3>
            <h1>{secondTitle}</h1>
            {children}
          </div>
        </Fade>
      </ContentSectionStyled>
    </StandardLayout>
  );
}
