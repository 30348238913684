import React from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import colors from "../styles/colors";
import StandardLayout from "./StandardLayout";

import bg2 from "../images/bg2.svg";
import ServiceCard from "./ServiceCard";
import cicle from "../images/cicle.svg";
import gears from "../images/gears.svg";
import app from "../images/app.svg";
import lock from "../images/lock.svg";

const services = [
  {
    title: "Consultoría",
    icon: cicle,
    items: [
      "Asesoría para proyectos de redes locales o amplias.",
      "Asesoría para el diseño de centros de computo.",
      "Capacitación y asesoría en sistemas operativos y aplicaciones.",
    ],
  },
  {
    title: "Soporte",
    icon: gears,
    items: [
      "Mantenimiento y reparación a tecnologías de la información y redes.",
      "Mantenimiento de sistemas de seguridad (cámaras de seguridad, alarmas, cercas eléctricas, foto celdas, video porteros).",
    ],
  },
  {
    title: "Desarrollo",
    icon: app,
    items: [
      "Proyectos de conectividad y comunicaciones.",
      "Soluciones internet e intranet.",
      "Soluciones de procesos para control administrativo.",
      "Soluciones de seguridad para redes.",
      "Software y páginas web.",
    ],
  },
  {
    title: "Venta e instalación",
    icon: lock,
    items: [
      "Equipo de computo e insumos.",
      "Cableado electrico para centros de computo y equipos especializados.",
      "Cableado estructurado (certificado).",
      "Conmutadores analógicos y digitales.",
      "Equipos especializados de almacenamiento masivo.",
      "Control de accesos (personas, vehículos, paquetería).",
      "Sistemas operativos para servidores.",
      "Sistemas de seguridad (cámaras de seguridad, alarma, cercas eléctricas, foto celdas, video porteros).",
      "Equipos de radiocomunicación.",
    ],
  },
];

const ServicesStyled = styled.div`
  overflow: hidden;

  h3 {
    font-weight: 800;
    margin-bottom: 1rem;
    color: ${colors.darkGray};
  }

  span {
    font-weight: 400;
    color: ${colors.secondary};
    text-decoration: underline;
  }

  h1 {
    font-size: 4rem;
    margin-bottom: 4rem;
    color: ${colors.primary};
  }

  p {
    margin-bottom: 2rem;
  }

  .background {
    position: absolute;
    right: 0;
    width: 700px;
    bottom: -200px;
    height: auto;
    overflow: hidden;
    z-index: -1;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .services {
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;

    @media (max-width: 1000px) {
      gap: 2rem;
    }
  }
`;

export default function Services({ firstTitle, secondTitle, children }) {
  return (
    <StandardLayout>
      <ServicesStyled>
        <div className="background">
          <img src={bg2} alt="" />
        </div>
        <Fade right>
          <div>
            <h3>¿A qué nos dedicamos?</h3>
            <h1>
              Nuestros <span>servicios</span>
            </h1>
            {children}
          </div>
        </Fade>
        <div className="services">
          {services.map((el) => (
            <ServiceCard
              title={el.title}
              icon={el.icon}
              items={el.items}
            ></ServiceCard>
          ))}
        </div>
      </ServicesStyled>
    </StandardLayout>
  );
}
