import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";
import ProjectCard from "./ProjectCard";
import StandardLayout from "./StandardLayout";

import installation from "../images/installation.png";
import home from "../images/home.png";
import app from "../images/app.png";

const projects = [
  {
    title: "Servicio",
    text: "Cableado estructurado de nodos de voz y datos para UIS del Gobierno del Estado de Puebla",
    image: installation,
  },
  {
    title: "Venta e instalación",
    text: "Instalacion de camaras de seguridad en mas de 500 comercios y casa habitación.",
    image: home,
  },
  {
    title: "Desarrollo",
    text: "Automatizacion de más de 2,000 comercios y casa habitación.",
    image: app,
  },
];

const ProjectsStyled = styled.div`
  text-align: center;

  h1 {
    color: ${colors.primary};
  }

  .projects {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6rem;
  }
`;

export default function Projects() {
  return (
    <StandardLayout backgroundColor={colors.lightGray}>
      <ProjectsStyled>
        <h3 style={{ color: colors.darkGray }}>Nuestra experiencia</h3>
        <h1>Proyectos</h1>
        <div className="projects">
          {projects?.map((el) => (
            <ProjectCard
              title={el.title}
              text={el.text}
              image={el.image}
            ></ProjectCard>
          ))}
        </div>
      </ProjectsStyled>
    </StandardLayout>
  );
}
