import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";
import StandardLayout from "./StandardLayout";

import altec from "../images/clients/altec.png";
import emece from "../images/clients/emece.png";
import kalawi from "../images/clients/kalawi.png";
import ocho30 from "../images/clients/ocho30.png";
import sp from "../images/clients/sp.png";
import FeaturedGeneric from "./FeaturedGeneric";

const ClientsStyled = styled.div`
  text-align: center;

  img {
    max-height: 3rem;
    max-width: auto;
  }
`;

export default function Clients() {
  return (
    <StandardLayout>
      <FeaturedGeneric title="Nuestros clientes">
        <img src={emece} alt="" />
        <img src={ocho30} alt="" />
        <img src={sp} alt="" />
        <img src={kalawi} alt="" />
        <img src={altec} alt="" />
      </FeaturedGeneric>
    </StandardLayout>
  );
}
