import React from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import colors from "../styles/colors";
import StandardLayout from "./StandardLayout";

import square from "../images/graphs/square.svg";

const ContentSectionStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1.5fr 1fr;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }

  div {
    align-self: center;
    justify-self: center;
  }

  h3 {
    font-weight: 800;
    margin-bottom: 1rem;
    color: ${colors.darkGray};
  }

  h2 {
    font-weight: 800;
  }

  h1 {
    font-size: 4rem;
    margin-bottom: 4rem;
  }

  img {
    max-width: 250px;
    height: auto;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  }

  p {
    color: ${colors.primary};
    margin-bottom: 2rem;
  }

  .background-fig {
    position: absolute;
    right: 0;
    top: 100px;
    width: 200px;
    overflow: hidden;
    @media (max-width: 650px) {
      display: none;
      visibility: hidden;
    }
  }

  .image-container {
    position: relative;
    height: 100%;

    @media (max-width: 650px) {
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
      justify-content: center;
    }
  }

  .image {
  }

  .image {
    &--1 {
    }

    &--2 {
    }

    &--3 {
    }
  }
`;

export default function ContentSection({
  firstTitle,
  secondTitle,
  picture1,
  picture2,
  picture3,
  children,
  imageStyles,
}) {
  return (
    <StandardLayout>
      <ContentSectionStyled>
        <img src={square} class="background-fig"></img>

        <Fade left>
          <div>
            <h3>{firstTitle}</h3>
            <h1>{secondTitle}</h1>
            {children}
          </div>
        </Fade>
        <Fade right>
          <div className="image-container">
            <div className="image image--1">
              <img src={picture3}></img>
            </div>
            <div className="image image--2">
              <img src={picture2}></img>
            </div>
            <div className="image image--3">
              <img src={picture1}></img>
            </div>
          </div>
        </Fade>
      </ContentSectionStyled>
    </StandardLayout>
  );
}
