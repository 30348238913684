import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import "../css/typography.css";

import Layout from "../components/layout";
import Seo from "../components/Seo";
import LandingTop from "../components/LandingTop";
import Providers from "../components/Providers";
import colors from "../styles/colors";
import Clients from "../components/Clients";
import ContentSection from "../components/ContentSection";
import ContentSectionRight from "../components/ContentSectionRight";

import camera from "../images/camera.png";
import graphs from "../images/graphs.png";
import networkswitch from "../images/networkswitch.jpg";
import lasersecurity from "../images/laser-security.png";

import Services from "../components/Servicios";
import Projects from "../components/Projects";
import EmptyContent from "../components/EmptyContent";
import TeamArray from "../components/TeamArray";
import MisionStatement from "../components/MisionStatement";
import Philosophy from "../components/Philosophy";

const IndexPage = () => (
  <Layout>
    <Seo title="Grupo Proizd" />
    <div id="home">
      <LandingTop></LandingTop>
    </div>
    <div id="us">
      <ContentSection
        firstTitle="¿Quiénes somos?"
        secondTitle="Sobre nosotros"
        picture1={lasersecurity}
        picture2={camera}
        picture3={networkswitch}
      >
        <div>
          <p>
            Grupo Proizd se funda en 2019. Somos una organización integradora de
            soluciones profesionales para las áreas de telecomunicación, redes y
            sistemas de seguridad, basados en la ciudad de Puebla.
          </p>
          <p>
            Somos una empresa consolidada, dispuesta a dar soluciones integrales
            para nuestros clientes dentro del sector público y privado. Contamos
            con capacitaciones certificadas para darle fortaleza a las
            soluciones propuestas, con equipo de la más alta calidad, y por
            supuesto, con la experiencia del mercado en la integración de
            proyectos exitosos, que cumple con los requerimientos de cada uno de
            nuestros clientes.
          </p>
        </div>
      </ContentSection>
      <ContentSectionRight
        firstTitle="¿Por qué existimos?"
        secondTitle="Nuestro propósito"
        picture={graphs}
        imageStyles={{
          alignSelf: "center",
          justifySelf: "center",
        }}
      >
        <div>
          <p>
            Somos un grupo de profesionales comprometidos y dedicados a cubrir
            las necesidades de nuestros clientes a través del servicio e
            integración de tecnologías, de manera innovadora, eficiente y
            funcional.{" "}
          </p>
        </div>
      </ContentSectionRight>
    </div>
    <div id="services">
      <Services></Services>
    </div>
    <div id="portfolio">
      <Projects></Projects>
    </div>
    <Clients></Clients>

    <EmptyContent firstTitle={"Conócenos"} secondTitle={"Nuestro equipo"}>
      <TeamArray></TeamArray>
    </EmptyContent>
    <MisionStatement></MisionStatement>
    <Philosophy></Philosophy>
    <Providers></Providers>
  </Layout>
);

export default IndexPage;
