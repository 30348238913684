import React from "react";
// Import css files
import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import colors from "../styles/colors";

import laserSecurity from "../images/laser-security.png";
import networkswitch from "../images/networkswitch.jpg";
import camera from "../images/camera.png";

import post1 from "../images/posts/post1.png";
import post2 from "../images/posts/post2.png";
import post3 from "../images/posts/post3.png";

const CarouselStyled = styled.div`
  padding: 40px;
  max-width: 500px;
  height: auto;

  @media (max-width: 530px) {
    width: 300px;
    padding: 0px;
  }

  .slick-slide img {
    margin: auto;
    width: 500px;
  }

  .slick-dots li button:before {
    font-size: 10px;
  }

  .slick-dots li button:before {
    color: ${colors.secondary};
  }
  .slick-dots li.slick-active button:before {
    color: ${colors.secondary};
  }
`;

export default function CarouselCustom() {
  const settings = {
    dots: true,
    autoplaySpeed: 3000,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <CarouselStyled>
      <Slider {...settings}>
        <div>
          <img src={post1} />
        </div>
        <div>
          <img src={post2} />
        </div>
        <div>
          <img src={post3} />
        </div>
      </Slider>
    </CarouselStyled>
  );
}
